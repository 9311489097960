.detailed-image {
  width: 50vw;
  height: max-content;
  align-self: center;
}

.main-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-left: 200px;
}

.roomdata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.circle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: 10px;
}

.circle-icon svg {
  color: #ffff;
}

.myButton {
  align-items: center;
}

.roomtext {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 2rem;
}

.roomtext h4 {
  font-size: 2rem;
  font-weight: 600;
  text-align: end;
  margin-right: 15%;
}

.ico {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  gap: 3px;
  padding: 5px 10px;
  width: 85%;
  justify-content: space-between;
}

.ico:nth-child(even) {
  background-color: rgb(226, 226, 226);
}

.ico div {
  display: flex;
  gap: 4px;
  align-items: center;
}

/* modal */

.react-datepicker__day--selected:not(.react-datepicker__day--today) > * {
  padding: 1.3em 3em;
  background-color: #8dd62c;
  color: white;
  font-weight: 700;
  font-size: 1.3rem;
}

.modal-dialog {
  position: absolute;
  float: right;
  z-index: 1;
  right: 10%;
  top: 20%;
}

.city {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-around;
}

.react-datepicker__day--selected {
  background-color: #8dd62c;
}

label {
  font-weight: bold;
}

input[type='text'] {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  height: 2rem;
  outline: none;
}

.react-datepicker__today-button {
  border: none;
  background-color: transparent;
  color: #8dd62c;
  font-weight: bolder;
  cursor: pointer;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 400px;
}

.react-datepicker {
  width: 100%;
  border: none;
}

.react-datepicker__navigation-icon--previous {
  color: #8dd62c;
}

.react-datepicker__header {
  background-color: transparent;
  text-align: center;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.react-datepicker__current-month {
  width: 100%;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.to {
  color: #8dd62c;
}

.reserve-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.btn {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  border: none;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.modal-title {
  text-transform: uppercase;
  letter-spacing: 2.5px;
  text-align: center;
}

.btn-primary {
  background-color: #8dd62c !important;
  color: #fff;
}

.btn-secondary {
  color: #000;
  background-color: #fff;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.mybtn {
  background-color: #8dd62c !important;
  border-radius: 28px;
  border: 1px solid #8dd62c;
  cursor: pointer;
  color: #fff;
  display: flex;
  gap: 15px;
  font-size: 17px;
  padding: 10px 15px;
  text-decoration: none;
  text-shadow: 0 1px 0 #2f6627;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.detailsMain {
  display: flex;
  flex-direction: column;
  margin-left: 6%;
  margin-top: 10%;
  gap: 30%;
  width: 100%;
}

.hours {
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: end;
  margin-right: 15%;
}

.reserveButton {
  padding: 5px 7px;
  gap: 10px;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .detailContainer {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
    width: 94%;
  }

  .detailed-image {
    width: 70vw;
    height: max-content;
    align-self: center;
    margin-top: 80px;
  }

  .roomtext {
    gap: 9px;
  }

  .detailsMain {
    margin-top: 0;
  }

  .reserveButton {
    margin-top: 10px;
  }
}
