.mainContainer,
.roomList {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.mainContainer h1 {
  color: black;
  margin: 5% 0;
  font-size: 1.9rem;
  font-weight: 900;
}

.mainContainer h2 {
  margin: 0;
  font-size: medium;
  color: rgb(155, 155, 155);
  font-weight: 500;
}

.menu {
  position: absolute;
  left: 3%;
  top: 2%;
}

.separator {
  color: lightgrey;
}

.roomContainer,
.roomContainer li {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 10px 0;
}

.roomContainer img {
  width: 70vw;
  border: 2px solid transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.roomContainer img:hover {
  border: 2px solid orange;
}

.roomContainer p {
  text-align: center;
  margin: 3%;
  color: rgb(155, 155, 155);
  font-weight: 500;
  font-size: 0.9rem;
}

.icons {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  color: rgb(167, 167, 167);
}

.icons span {
  border: 1px solid rgb(194, 194, 194);
  border-radius: 20px;
  list-style: none;
  display: flex;
  width: 25px;
  height: 25px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.roomNames {
  margin: 2% 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.separatorTop {
  display: none;
}

.roomsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roomsBox h1 {
  margin-top: 40px;
}

a {
  list-style: none;
  text-decoration: none;
  color: black;
  margin: 0;
  padding: 0;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.slider-container {
  display: none;
}

.noReservation {
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ff3d00 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slider-control-bottomcenter {
  display: none;
}

@media only screen and (min-width: 768px) {
  .mobileRooms {
    display: none;
  }

  .slider-container {
    display: block;
  }

  .mainContainer {
    margin-left: 202px;
  }

  .mainContainer h1 {
    margin-top: 7%;
    margin-bottom: 1%;
  }

  .mainContainer h2 {
    margin-bottom: 3%;
  }

  .roomContainer {
    display: grid;
    grid-template-columns: 90%;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
  }

  .roomContainer img {
    width: 97vw;
  }

  .separatorTop {
    display: block;
    color: lightgrey;
  }

  .formContainer {
    margin-left: 108px;
  }

  .slider-control-centerleft button {
    margin-left: -3.5vw;
    background: rgb(128, 187, 40) !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 600;
  }

  .slider-control-centerright button {
    margin-right: -3.5vw;
    background: rgb(128, 187, 40) !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: 600;
  }

  .logout {
    padding: 8px 13px;
    text-align: start;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 100;
    color: #171717;
  }

  .slider-list {
    gap: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .slider-control-centerleft button {
    margin-left: -4vw;
  }

  .slider-control-centerright button {
    margin-right: -4vw;
  }
}
