.mobile-menu {
  position: absolute;
  z-index: 999;
  width: 100%;
}

.menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.801) !important;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
}

.hamburger span {
  height: 3px;
  width: 100%;
  background-color: white;
  transition: all 0.2s ease;
}

.hamburger.open span:first-child {
  transform: rotate(45deg) translate(8px, 8px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:last-child {
  transform: rotate(-45deg) translate(4px, -4px);
}

.menu-items {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.menu-items.open {
  max-height: 300px;
}

.menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.801);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.menu-items li {
  margin: 0;
}

.menu-items p {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #fcf8f8;
  transition: background-color 0.2s ease;
}

.menu-items p:hover {
  background-color: rgb(65, 65, 65);
}

@media only screen and (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
